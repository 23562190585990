/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
:root {
    --ion-font-family: 'Rubik', sans-serif;
}
.jos-font{
    font-family: 'Josefin Sans', sans-serif;
}
.kenit-font{
    font-family: 'Kanit', sans-serif;
}
.raleway-font{
    font-family: 'Raleway', sans-serif;
}
.wishcomp{
    --width:350px;
}
.searchbar-input.sc-ion-searchbar-md {
    padding-left: unset;
    padding-right: unset;
    -webkit-padding-start: 55px;
    padding-inline-start: 55px;
    -webkit-padding-end: 55px;
    padding-inline-end: 55px;
    background: #f5f5f5;
    border-radius: 10px;
    height:30px;
}
.searchbar-search-icon.sc-ion-searchbar-md {
    left: 16px;
    top: 3px;
    width: 21px;
    height: 21px;
}
.paytypepop{
    --width:350px;
}
.catlocsearch{
    --width:250px;
    top:-100px;
   
}
.servicecomp{
    --width:340px;
}
.addClass{
    --width:340px;
    top:60px;
}
.my-custom-class{
    --width:340px;
}
.filtclass{
    --width:93%;
    --height:100%;
}
@media (max-width: 800px) {
    .header{
        img{
            width: 200px;
        }
    }
}
@media (max-width: 414px){
    .header{
        img{
            width: 200px;
        }
    }
    .search{
        display: none!important;
    }
    .ucicons{
        float: right;
        display: flex;
    }
    .menu{
        display: none!important;
    }
}
.header{
    margin:0;
    width: 100%;
    border-bottom: 1px solid #d2d2d2;
    img{
        cursor: pointer;
    }
    .search{
        display: flex;
        select{
            width: 170px;
            padding: 15px;
            background-color: #f8f8f8;
            border: 1px solid #ededed;
            cursor: pointer;
        }
        ion-input{
            width: calc(100% - 170px);
            background-color: #f8f8f8;
            border: 1px solid #ededed;
            border-left: none;
            padding: 9px 24px;
        }
        ion-icon{
            border: 1px solid #ededed;
            background-color: #f8f8f8;
            color: #a1b1bc;
            font-size: 30px;
            padding: 10px;
            cursor: pointer;
        }
    }
    .admindet{
        display: flex;
        padding: 0;
        li{
            list-style-type: none;
            padding:0 15px;
            display: flex;
        }
    }
    .ucicons{
        ion-icon{
            font-size: 30px;
            padding: 12px;
            cursor: pointer;
        }
    }
    .menu{
      display: flex; 
      margin-left: 25%;
      li{
          list-style-type: none;
          padding: 0 20px;
          cursor: pointer;
      } 
    }
}
.footer{
    .fdetails{
    padding-top: 60px;
    padding-bottom: 60px;
    border-bottom: 1px solid #e5e5e5;
    }
    img{
        width:120px;
    }
    .ficons{
        display:flex;
        padding: 20px 0;
        ion-icon{
            width: 25px;
            height: 25px;
            line-height: 42px;
            text-align: center;
            color: #8a8e9b;
            margin-right: 7px;
        }
    }
    .pc{
    font-size: 18px;
    font-weight: 600;
    margin: 20px 25px;
    }
    ul{
        li{
            list-style-type: none;
            font-size: 16px;
            color: #4b566b;
            line-height: 36px;
            cursor: pointer;
        }
    }
    .copyright{
        padding: 30px 0;
        .ptlink{
            float:right;
        }
        span{
            padding: 0 20px;
        }
    }
}

.product-item {
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden;
    transition: all 0.3s ease;
    cursor: pointer;
    .heart{
        position: absolute;
        right: 15px;
        top: 12px;
        z-index: 9;
        font-size: 30px;
        color: #ff484a;
        background: #f2efef;
        padding: 3px;
        border-radius: 50%;
    }
    .cart{
        position: absolute;
        right: 15px;
        bottom: 30px;
        z-index: 9;
        font-size: 20px;
        color: #4e28b8;
        background: #f8f6ff;
        padding: 3px;
        border-radius: 5px;
        span{
            font-size: 12px;
            position: relative;
            top: -5px;
        }
    }
   
    .product-thumb {
        position: relative;
        text-align: center;
    }
.batch {
    position: absolute;
    left: 15px;
    top: 20px;
    padding: 5px 10px;
    font-size: 11px;
    text-transform: uppercase;
    color: white;
    background-color: #2d4992;
    font-weight: 500;
    line-height: 1;
    min-width: 45px;
    text-align: center;
    border-radius: 1px;
    z-index: 9;
}
.product-content {
    padding: 25px 20px;
    .cata{
        font-size: 12px;
        color: #ccc;
        margin-bottom: 6px;
        line-height: 1;
    }
    .product-title{
        font-size: 15px;
        font-weight: 600;
        color: #363636;
        margin: 5px 0;
    }
    .price{
        color: #4e28b8;
        font-size: 14px;
    }
}
}
.more-product-btn {
    font-size: 14px;
    padding: 10px 32px;
    border-radius: 5px;
    background-color: #4e28b8;
    color: #fff;
    cursor: pointer;
}